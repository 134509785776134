import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      show: "radio_button_checked",
      hide: false,
      comp1: false,
      comp2: false,
      comp3: false,
      comp4: false,
    };
  }

  showMore1() {
    this.setState({ comp1: !this.state.comp1 });
  }
  showMore2() {
    this.setState({ comp2: !this.state.comp2 });
  }
  showMore3() {
    this.setState({ comp3: !this.state.comp3 });
  }
  showMore4() {
    this.setState({ comp4: !this.state.comp4 });
  }

  render() {
    const hash = "#";
    let { comp1, comp2, comp3, comp4 } = this.state;

    const imageIcon = {
      width: "48px",
      display: "inline-block",
      float: "left",
    };
    const iconS = {
      display: "inline-block",
      padding: "0",
      margin: "0",
    };

    return (
      <>
        <div>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "rgb(229, 236, 255, 0.9)",
                color: "#111",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(229, 236, 255)",
              }}
              date="December 2018 - present"
              iconStyle={{
                background: "rgb(229, 236, 255)",
                color: "#444",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
              icon={"18-P"}
            >
              <h4 className="vertical-timeline-element-title">
                Front-end Architect / Senior Front-end developer
              </h4>
              <h5 className="vertical-timeline-element-subtitle">
                Frasers Property through Espire Info Lab, Australia, NSW
              </h5>
              {comp1 && (
                <>
                  <p>
                    Developed FPA Sales APP (IOS/Android/Desktop) for buying the
                    property, well managed whole hybrid app from scratch
                    (Architecture, approval, development), successfully
                    delivered on time.
                  </p>
                  <p>
                    Proud to say within 2.5sec the agent can hold the property
                    (behind the scenes well managed multiple server APIs request
                    as per the performance point of view).
                  </p>
                  <p>
                    Implemented Australia address/phone validation and casting
                    functionality so the agent can cast photos/video/pdf on the
                    big screen.
                  </p>
                  <p>
                    Successfully delivered the Coorparoo website theme base so
                    the marketing team can change them as per the project.
                  </p>
                   
                  <p>
                    Etracker bug management system hybrid app, well-managed
                    online/offline functionality
                  </p>
                   
                  <p>
                    Thousands of data were well managed as per the performance
                    point of view and created reusable multiple components.
                  </p>
                </>
              )}
              <div className="showMore" onClick={() => this.showMore1()}>
                {comp1 ? (
                  <>
                    Show less{" "}
                    <i className="material-icons">indeterminate_check_box</i>
                  </>
                ) : (
                  <>
                    Show more <i className="material-icons">add_box</i>
                  </>
                )}
              </div>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "rgb(229, 236, 255, 0.9)",
                color: "#111",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(229, 236, 255)",
              }}
              date="June 2015 - December 2018"
              iconStyle={{
                background: "rgb(229, 236, 255)",
                color: "#333",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
              icon={"15-18"}
            >
              <h4 className="vertical-timeline-element-title">
                UI lead / Sr. Front-end developer
              </h4>
              <h5 className="vertical-timeline-element-subtitle">
                Espire Info Lab, India, Gurugram
              </h5>
              {comp2 && (
                <>
                  <p>
                    Developed Aggreko website and follow the best practice with
                    Accessibility 2.0 and w3c validation.
                  </p>
                  <p>
                    Developed Aggreko website and follow the best practice with
                    Accessibility 2.0 and w3c validation.
                  </p>
                  <p>
                    Review and refactored the code and time-to-time demo as per
                    the feedback point of view.
                  </p>
                  <p>
                    Developed reusable components and well collaborate with the
                    team and created the technical documentation.
                  </p>
                  <p>Wrote technical documentation.</p>
                  <p>
                    Powerful skills in API development, specifically for web
                    use.
                  </p>
                  <p>Responsible for completing the task and fixing the bug.</p>
                  <p>Well-managed local database.</p>
                  <p>Successfully delivered all the sprints.</p>
                  <p>
                    While the developer and finishing Jira tasks, nurturing the
                    team well.
                  </p>
                  <p>
                    Translate designs to front-end code, estimate project time
                    and costs, determine developer requirements and
                    specifications with proper documentation and work with a
                    cross-functional team to define and develop product
                    roadmaps.
                  </p>
                  <p>
                    Proficient in developing web applications as per industry
                    benchmarks.
                  </p>
                  <p>
                    Engaged in requirement refinement with business,
                    understanding UI/UX (design), solutions, delivery, and
                    reporting.
                  </p>
                  <p>
                    Engaged in requirement refinement with business,
                    understanding UI/UX (design), solutions, delivery, and
                    reporting.
                  </p>
                  <p>
                    Proficient in developing web Application and effectively
                    using, HTML5, CSS3, SCSS, jQuery, JavaScript, Vue.js,
                    Handlebar, Photoshop and also experience in making webpage
                    cross-browser compatible.
                  </p>
                  ]
                  <p>
                    Followed the rules of the coding guideline and did HTML unit
                    testing, W3C validation, Accessibility 2.0.
                  </p>
                  <p>
                    Maintain and provide support documentation and provide
                    training to internal and external users.
                  </p>
                  <p>
                    User Interface (layouts) design in Adobe Photoshop,
                    considering framework (Bootstrap, semantic, JS framework).
                  </p>
                  <p>
                    Learn new technologies needed in the project and conduct
                    internal knowledge sharing sessions in our team to
                    facilitate knowledge skills development.
                  </p>
                </>
              )}
              <div className="showMore" onClick={() => this.showMore2()}>
                {comp2 ? (
                  <>
                    Show less{" "}
                    <i className="material-icons">indeterminate_check_box</i>
                  </>
                ) : (
                  <>
                    Show more <i className="material-icons">add_box</i>
                  </>
                )}
              </div>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "rgb(183, 204, 176, 0.9)",
                color: "#111",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(183, 204, 176)",
              }}
              iconStyle={{
                background: "rgb(183, 204, 176)",
                color: "#333",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
              date="MAY 2012 – MAY 2015"
              icon={"12-15"}
            >
              <h4 className="vertical-timeline-element-title">UI/UX Developer</h4>
              <h5 className="vertical-timeline-element-subtitle">
                E-Meditec Technologies, India, Gurugram
              </h5>
              {comp3 && (
                <>
                  <p>
                    Translate designs to front-end code, determine developer
                    requirements and specifications. Maintain the coding
                    standards as per W3C, provided advice and guidance as per
                    the front-end developments.
                  </p>
                  <p>
                  Followed coding standards with unit testing, and integration with the backend team. Translate design into functional user interfaces, ensuring cross-browser compatibility with global standard also worked on User Interface (layouts) design in Photoshop.
                  </p>
                  <p>
                    Follow coding standards, with complete unit testing and
                    integration with the backend team if the backend team
                    members need help.
                  </p>
                  <p>
                    Providing advice, guidance and expertise related to
                    Front-End in the web development.
                  </p>
                  <p>
                    Engaged in requirement refinement with business,
                    understanding UI/UX (design), solutions, delivery, and
                    reporting.
                  </p>
                  <p>Created User Interface (layouts) design in Photoshop.</p>
                  <p>
                    Responsible for writing and maintaining codes in accordance
                    with the technical design.
                  </p>
                  <p>Worked on documentation and gave a demo.</p>
                  <p>
                    Translated design into functional user interfaces, ensuring
                    cross-browser compatibility, responsive website and
                    performance.
                  </p>
                  <p>
                    Developed applications using front-end technologies (HTML,
                    JavaScript, CSS3 and Photoshop).
                  </p>
                  <p>
                    Time to time analysing and identifying the best approach
                    (technology).
                  </p>
                  <p>
                    Code refactoring, bug and errors fixing, issue
                    identification.
                  </p>
                  <p>
                    Code modification and maintenance after reviewing and
                    modified the code as per the global standards such as W3C
                    validation, Accessibility 2.0, SEO friendly code, automation
                    deployment.
                  </p>
                  <p>
                    Learn new technologies needed in the project and conduct
                    internal knowledge sharing sessions in our team to
                    facilitate knowledge skills development.
                  </p>
                </>
              )}
              <div className="showMore" onClick={() => this.showMore3()}>
                {comp3 ? (
                  <>
                    Show less{" "}
                    <i className="material-icons">indeterminate_check_box</i>
                  </>
                ) : (
                  <>
                    Show more <i className="material-icons">add_box</i>
                  </>
                )}
              </div>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "rgb(204, 184, 176, 0.9)",
                color: "#111",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(204, 184, 176)",
              }}
              date="MAY 2011 – MAY 2012"
              iconStyle={{
                background: "rgb(204, 184, 176)",
                color: "#333",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
              icon={"11-12"}
            >
              <h4 className="vertical-timeline-element-title">
                Graphic Designer
              </h4>
              <h5 className="vertical-timeline-element-subtitle">
                Adixtion, India, Noida
              </h5>
              {comp4 && (
                <>
                  <p>
                    Create a website design template using Dreamweaver,
                    Photoshop and Flash using action script 2.0, created a web
                    intro.
                  </p>
                  <p>
                    Follow design principle as per the industry standard and
                    work on design and development tools.
                  </p>
                  <p>
                    HTML web templates using web designing tools like
                    Dreamweaver and Photoshop.
                  </p>
                  <p>
                    Translated design into functional user interfaces, ensuring
                    cross-browser compatibility, responsive website and
                    performance.
                  </p>
                  <p>
                    HTML web templates using web designing tools like
                    Dreamweaver and Photoshop.
                  </p>
                  <p>
                    Create User Interface (layouts) design in Photoshop,
                    considering web framework.
                  </p>
                  <p>
                    Design Brochures, Case Studies, Catalogue, Advertisements,
                    Website Banners, Hoardings, Posters, etc.
                  </p>
                  <p>Worked on action script 2.0 in Flash for website Intro.</p>
                  <p>
                    Learn new technologies needed in the project and conduct
                    internal knowledge sharing sessions in our team to
                    facilitate knowledge skills development.
                  </p>
                </>
              )}
              <div className="showMore" onClick={() => this.showMore4()}>
                {comp4 ? (
                  <>
                    Show less{" "}
                    <i className="material-icons">indeterminate_check_box</i>
                  </>
                ) : (
                  <>
                    Show more <i className="material-icons">add_box</i>
                  </>
                )}
              </div>
            </VerticalTimelineElement>
          </VerticalTimeline>{" "}
        </div>{" "}
      </>
    );
  }
}
