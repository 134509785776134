import React from 'react';
import './../bootstrap.css';

export default class Blog extends React.Component {
	//constructor Information define props and state
	//We are being defined this state inside this
	constructor(props) {
	  super(props);
	
	  this.state = {
	  	arry: [2,2,2,2,3,34,4,5,6],
	  };
	}

	componentDidMount(){
		let vals = this.state.arry;
	}

	loop(){
		let arry = this.state.arry;
		let sum = 0;
		for(let arr of arry){
			sum += arr
		}
		return sum;
	}

	render() {
		return (

			<>
      		<div className="alert alert-primary" role="alert">
			  For loop + let arr of arrys + reduce
			</div>
			{this.loop()}




      		</>
      		);
	}
}




