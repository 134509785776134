import React from 'react';

export default (props) => (
  <div className="col-md-auto col-badge text-center">
	<figure>
		<img src={props.src} alt={props.text} className="mb-2" />
		<figcaption>{props.text}</figcaption>
	</figure>
  </div>
);



