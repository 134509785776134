import React from "react";
import { Route, Switch } from 'react-router-dom';
import "./App.css";
import Home from './components/home.jsx';
import Shop from './components/shop.jsx';
import Projects from './components/projects.jsx';


class App extends React.Component {
  render() {
    return (
      <>
        <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/projects" component={Projects} />
            <Route path="/shop" component={Shop} />
        </Switch>
        
      </>
    );
  }
}

export default App;
