import React from "react";
import "../App.css";
import Particles from "react-particles-js";
import Card from "./card.jsx";
import ProjectSlide from "./projects.jsx";
import Exp from "./experience.jsx";
import ExpCard from "./expCard";

const particleOpt = {
  particles: {
    number: {
      value: 180,
      density: {
        enable: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: "top",
      out_mode: "out",
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: "bubble",
      },
      onclick: {
        enable: true,
        mode: "repulse",
      },
    },
    modes: {
      bubble: {
        distance: 250,
        duration: 2,
        size: 0,
        opacity: 0,
      },
      repulse: {
        distance: 400,
        duration: 4,
      },
    },
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "menu",
      proj: false,
      main: true,
      exp: false,
      classActive: 0,
    };
  }

  lightMenu(e) {
    this.setState({
      menu: "menu",
    });
  }

  activeClass(e) {
    this.setState({
      classActive: e,
    });
  }

  project(e) {
    this.setState({
      proj: !this.state.proj,
      main: !this.state.main,
    });
    let itemId = e.currentTarget.dataset.id;

    setTimeout(() => {
      document.getElementById(itemId).click();
    }, 500);
  }

  exp(e) {
    this.setState({
      proj: false,
      main: false,
      exp: true,
    });
  }

  close() {
    this.setState({
      proj: !this.state.proj,
      main: !this.state.main,
    });
  }

  closeExp() {
    this.setState({
      proj: false,
      exp: false,
      main: true,
    });

    setTimeout(() => {
      document.getElementById("projects").click();
    }, 500);
  }

  darkMenu() {
    this.setState({
      menu: "darkLight menu",
    });
  }
  noLabelMenu() {
    this.setState({
      menu: "noLabel menu",
    });
  }

  render() {
    const styles = {
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      with: "100%",
    };
    const test = "#";
    return (
      <>
        {this.state.main && (
          <main>
            {/* <div className={this.state.menu}>
              <ul>
                <li>
                  <a
                    href={test}
                    className={this.state.classActive === 0 ? "active" : ""}
                    onClick={e => {
                      this.lightMenu();
                      this.activeClass(0);
                    }}
                  >
                    <i className="material-icons">home</i>
                  </a>
                  <span>Home</span>
                </li>

                <li>
                  <a
                    href={test}
                    className={this.state.classActive === 1 ? "active" : ""}
                    onClick={e => {
                      this.darkMenu();
                      this.activeClass(1);
                    }}
                  >
                    <i className="material-icons">info_outline</i>
                  </a>
                  <span>About</span>
                </li>

                <li>
                  <a
                    href={test}
                    className={this.state.classActive === 2 ? "active" : ""}
                    onClick={e => {
                      this.darkMenu();
                      this.activeClass(2);
                    }}
                  >
                    <i className="material-icons">timeline</i>
                  </a>
                  <span>Skills</span>
                </li>
                <li>
                  <a
                    href={test}
                    className={this.state.classActive === 3 ? "active" : ""}
                    data-id="expEv6"
                    onClick={e => this.exp(e)}
                  >
                    <i className="material-icons">card_travel</i>
                  </a>
                  <span>Experience</span>
                </li>
                <li>
                  <a
                    href={test}
                    className={this.state.classActive === 4 ? "active" : ""}
                    id="projects"
                    onClick={e => {
                      this.noLabelMenu();
                      this.activeClass(4);
                    }}
                  >
                    <i className="material-icons">person_pin</i>
                  </a>
                  <span>Projects</span>
                </li>
                <li>
                  <a
                    href={test}
                    className={this.state.classActive === 5 ? "active" : ""}
                    onClick={e => {
                      this.darkMenu();
                      this.activeClass(5);
                    }}
                  >
                    <i className="material-icons">star_border</i>
                  </a>
                  <span>Certification</span>
                </li>
                <li>
                  <a
                    href={test}
                    className={this.state.classActive === 6 ? "active" : ""}
                    onClick={e => {
                      this.lightMenu();
                      this.activeClass(6);
                    }}
                  >
                    <i className="material-icons">local_library</i>
                  </a>
                  <span>Education</span>
                </li>
                <li>
                  <a
                    href={test}
                    className={this.state.classActive === 7 ? "active" : ""}
                    onClick={e => {
                      this.lightMenu();
                      this.activeClass(7);
                    }}
                  >
                    <i className="material-icons">contact_phone</i>
                    <span></span>
                  </a>
                  <span>Contact</span>
                </li>
              </ul>
            </div> */}

            <header>
              <section className="header-content">
                <div className="header-img animate-pop-in">
                  <img
                    src="./images/profile-pic.jpg"
                    className="img-thumbnail"
                  />
                </div>
                <h1 className="header-title animate-pop-in">
                  Hi, I'm Vinod kumar
                </h1>
                <h3 className="header-subtitle animate-pop-in">
                  Front-end Developer & JavaScript professional, I love what I
                  do.
                </h3>
              </section>
              <a href="#about" className="header-down-arrow">
                <svg
                  className="chevron-down"
                  viewBox="0 0 448 512"
                  width="100"
                  title="chevron-down"
                >
                  <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
                </svg>
              </a>
            </header>

            <section className="about-me">
              <div className="container">
                <div className="row">
                  <div className="col text-center">
                    <h2 id="about">About</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="card about mb-3 no-border text-center">
                      <div className="card-body">
                        <h4 className="card-title">
                          A self-driven &amp; self-organized professional, who believes in value creation in work delivery.
                          By keeping the solution in gravity of my roles and
                          responsibilities, I trust in teamwork and
                          challenges driven improvements.
                        </h4>
                        <h5 className="card-text">
                            As a Frontend developer, I enjoyed my obsessive
                            attention to details, follow the global standard,
                            reviewing codes, I prefer
                            to keep learning and love to continue challenging
                            myself.
                        </h5>
                        <h5 className="card-text">
                            Passion for creating immersive user experiences, and
                            more than 11 years of experience, building
                            user-facing products, and helping to create and
                            maintain a better code base for reusability.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="skills">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h2>Skills</h2>
                  </div>
                </div>

                <div className="row ">
                  <h4>Html</h4>
                </div>
                <div className="row ">
                  <Card src="./images/html5.png" text="HTML" />
                  <Card src="./images/react.png" text="React" />
                  <Card src="./images/jade.png" text="Jade" />
                  <Card src="./images/zepui.png" text="ZEPPUI" />
                </div>

                <div className="row ">
                  <h4>CSS</h4>
                </div>
                <div className="row">
                  <Card src="./images/css3.png" text="HTML" />
                  <Card src="./images/sass.png" text="Sass" />
                  <Card src="./images/less_logo.png" text="Less" />
                  <Card src="./images/bootstrap.png" text="Bootstrap" />
                  <Card src="./images/semanticUI.png" text="SemanticUI.png" />
                  <Card src="./images/foundation.png" text="Foundation" />
                  <Card src="./images/materialize.png" text="Materialize" />
                </div>

                <div className="row ">
                  <h4>JavaScript</h4>
                </div>

                <div className="row">
                  <Card src="./images/javascript.png" text="Java Script" />
                  <Card src="./images/react.png" text="React" />
                  <Card src="./images/redux.png" text="Redux" />
                  <Card src="./images/ts.png" text="TypeScript" />
                  <Card src="./images/vue.png" text="vue" />
                  <Card src="./images/handlebars_logo.png" text="Handlebar" />
                  <Card src="./images/next.png" text="NEXT.js" />
                  <Card src="./images/angular.png" text="Angular" />
                  <Card src="./images/jquery.png" text="jQuery" />
                  <Card src="./images/nodejs.png" text="Node" />
                  <Card src="./images/framework7.png" text="Framework7" />
                </div>

                <div className="row ">
                  <h4>Buil4 tools</h4>
                </div>
                <div className="row">
                  <Card src="./images/webpack.png" text="Webpack" />
                  <Card src="./images/vite.png" text="Vite.js" />
                  <Card src="./images/gulp.png" text="Gulp" />
                  <Card src="./images/grunt.png" text="Gurnt" />
                  <Card src="./images/bower.png" text="Bower" />
                </div>

                <div className="row">
                  <h4>Others</h4>
                </div>
                <div className="row">
                  <Card src="./images/git.png" text="Git" />
                  <Card src="./images/jira.png" text="Jira" />
                  <Card src="./images/github.png" text="Github" />
                  <Card src="./images/photoshoplogo.jpg" text="Photoshop" />
                  <Card src="./images/axure.png" text="Axure" />
                </div>
              </div>
            </section>

            <section style={{ position: "relative" }} className="experience">
              <div className="container">
                <div className="row">
                  <div className="col text-center">
                    <h2>Experience</h2>
                  </div>
                </div>
                <Exp projects={(e) => this.closeExp(e)} />
              </div>
            </section>

            <section className="website">
              <div className="row">
                <div className="col text-center mt-3">
                  <h3>APP</h3>
                </div>
              </div>
              <div className="card-group container">
                <ExpCard
                  src="./images/fpasalesapp.png"
                  title="FPA SALES APP"
                  text="This APP is design to improve the way Internal and External agents works."
                  updateL="Last updated 3 mins ago"
                  clickEvent={(e) => this.project(e)}
                  dataid="projEv1"
                  href="https://fpasales.frasersproperty.com.au/"
                />
                <ExpCard
                  src="./images/etracker.png"
                  title="ETRACKER APP"
                  text="Defect Management Mobile Application to effectively control and manage..."
                  updateL="Last updated 3 mins ago"
                  clickEvent={(e) => this.project(e)}
                  dataid="projEv2"
                  href="https://petrackerv3.frasersproperty.com.au/"
                />
              </div>
              <div className="row">
                <div className="col text-center mt-3">
                  <h3>Website</h3>
                </div>
              </div>
              <div className="card-group container">
                <ExpCard
                  src="./images/aggreko.png"
                  title="Aggreko"
                  text="Aggreko grown from a small local..."
                  updateL="Last updated 3 mins ago"
                  clickEvent={(e) => this.project(e)}
                  dataid="projEv3"
                  href="https://www.aggreko.com/en-au"
                />

                <ExpCard
                  src="./images/glory-global.png"
                  title="Glory global"
                  text="Aggreko grown from a small local..."
                  updateL="Last updated 3 mins ago"
                  clickEvent={(e) => this.project(e)}
                  dataid="projEv4"
                  href="https://www.glory-global.com/en-us/"
                />
              </div>

              <div className="card-group container">
                <ExpCard
                  src="./images/toyotafleetmanagement.png"
                  title="Toyota - Fleet Management"
                  text="Benefit from the strength of partnering with..."
                  updateL="Last updated 3 mins ago"
                  clickEvent={(e) => this.project(e)}
                  dataid="projEv5"
                  href="https://www.toyotafleetmanagement.com.au/"
                />

                <ExpCard
                  src="./images/coorparoosquare.shopping_.png"
                  title="Coorparoo Square"
                  text="Shopping, dining and entertainment..."
                  updateL="Last updated 3 mins ago"
                  clickEvent={(e) => this.project(e)}
                  dataid="projEv6"
                  href="https://www.coorparoosquare.shopping/"
                />
              </div>
            </section>
            <section className="certificate">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h2 class="text-center">Certification</h2>
                    <div className="certification">
                      <div style={{ marginRight: "30px" }}>
                        <img
                          src="./images/Programming.png"
                          alt="Programming in HTML5 with JavaScript and CSS3"
                        />
                      </div>
                      <div>
                        <h3>Programming in HTML5 with JavaScript and CSS3</h3>
                        <p>
                          Passing Exam 480: Programming in HTML5 with JavaScript
                          and CSS3 validates a candidate’s ability to access and
                          secure data as well as implement document structures,
                          objects, and program flow.
                        </p>
                        <h5>Skills</h5>
                        <ul className="tags">
                          <li>Web Developer</li>
                          <li>Application Developer</li>
                          <li>CSS3</li>
                          <li>Data Security</li>
                          <li>Data Validation</li>
                          <li>HTML5</li>
                          <li>JavaScript</li>
                          <li>based Programming</li>
                          <li>Visual Studio</li>
                          <li>Web Applications</li>
                        </ul>
                        <h5>Earning Criteria</h5>
                        <p>VALIDATE SKILLS IN THE FOLLOWING AREAS:</p>
                        <ul className="list">
                          <li>
                            Implement and manipulate document structures and
                            objects
                          </li>
                          <li>CSS3</li>
                          <li> Implement program flow</li>
                          <li>Access and secure data</li>
                          <li>Use CSS3 in applications</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="education pannel6">
              <div className="container">
                <h2
                  style={{
                    textAlign: "center",
                    textShadow: "none",
                  }}
                >
                  Education
                </h2>

                <ul>
                  <li>Delhi University, Bachelor of Arts (India)</li>
                  <li>
                    Complete Multimedia (Graphic Design, 2d and 3d Animation)
                    Diploma from Arena Animation Academy, Rajouri Garden
                    (India).
                  </li>
                  <li>
                    Complete Web (Html, JavaScript) from Arena Animation
                    Academy, Rajouri Garden (India).
                  </li>
                </ul>
              </div>
            </section>

            <section className="contact">
              <div className="container">
                <h2
                  style={{
                    textAlign: "center",
                    textShadow: "none",
                  }}
                >
                  Contact Me
                </h2>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <span>Email: </span>
                    <a href="mailto:vinodcg2@gmail.com">vinodcg2@gmail.com</a>
                  </li>
                  <li className="list-group-item">
                    <span>
                      Linkedin: <i className="fab fa-linkedin-in"></i>
                    </span>
                    <a href="https://www.linkedin.com/in/vinodcg2/">
                      https://www.linkedin.com/in/vinodcg2/
                    </a>
                  </li>
                  <li className="list-group-item">
                    <span>Portfolio: </span>
                    http://vkc3.com/
                  </li>
                </ul>
              </div>
            </section>
          </main>
        )}{" "}
        {this.state.proj && (
          <ProjectSlide projects={(e) => this.close(e)} />
        )}{" "}
      </>
    );
  }
}

export default App;
