import React from 'react';

export default (props) => (
    <div className="card col-md-6">
        <img src={props.src} className="card-img-top" alt="..." />
        <div className="card-body">
            <h5 className="card-title">{props.title}</h5>
            <p className="card-text">{props.text}</p>
            <div className="row">
                <a data-id={props.dataid} onClick={props.clickEvent} className="btn btn-outline-primary col">Details</a>
                <a href={props.href} target="_blank"className="btn btn-outline-primary col">Live</a>
            </div>
            
        </div>
    </div>
);